import { Link } from "@/components/common";
import { Button } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { RiCloseFill } from "react-icons/ri";

export const ExitPreviewButton = () => {
  const { isPreview } = useRouter();

  return (
    <>
      {isPreview ? (
        <Button variant="primary" position="fixed" bottom="30px" right="30px" rightIcon={<RiCloseFill />}>
          <Link href="/api/exit-preview" _hover={{ textDecoration: "none" }}>
            Exit preview
          </Link>
        </Button>
      ) : null}
    </>
  );
};
