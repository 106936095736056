import { Banner } from "@/components/Banner";
import { Footer, Header } from "@/components/common";
import { BannerData } from "@/types/banner-data";
import { MenuData } from "@/types/menu-data";
import type { Page } from "@/types/page";
import { Box } from "@chakra-ui/react";
import { ExitPreviewButton } from "./ExitPreviewButton";

export interface LayoutProps {
  children: React.ReactNode;
  bannerData?: BannerData[];
  headerVariant?: "primary" | "transparent";
  menuData: MenuData;
}

export const Layout = ({ children, bannerData, headerVariant, menuData }: LayoutProps) => {
  return (
    <Box bg="white">
      {bannerData && bannerData.length > 0
        ? bannerData.map((banner) => <Banner key={banner.uid} bannerData={banner} />)
        : null}
      {menuData ? <Header navigationData={menuData.headerNavigation} variant={headerVariant} /> : null}
      <Box as="main" minHeight="100%" mt="-72px">
        {children}
      </Box>
      {menuData ? <Footer navigationData={menuData.footerNavigation} /> : null}
      <ExitPreviewButton />
    </Box>
  );
};

export const getLayout = (page: Page<unknown>, menuData: MenuData, bannerData?: BannerData[]) => (
  <Layout bannerData={bannerData} menuData={menuData}>
    {page}
  </Layout>
);
