import type { RTHeading2Node } from "@prismicio/types";

/**
 * Utility function to get grouped headings from a Prismic page
 */
export const getTOCGroupedHeadings = (slices: { slice_type: string; primary: { content: RTHeading2Node[] } }[]) => {
  const textSections = (slices ?? []).filter((slice) => slice.slice_type === "text_section");

  if (!textSections) {
    return [];
  }

  const headings = textSections.flatMap((textSection) =>
    textSection.primary.content?.filter(
      (richText) => (richText.type === "heading2" || richText.type === "heading4") && Boolean(richText.text),
    ),
  );

  const groupedHeadings: { heading: RTHeading2Node; items: RTHeading2Node[] }[] = [];

  for (const heading of headings) {
    if (heading.type === "heading2") {
      groupedHeadings.push({
        heading: heading,
        items: [],
      });
    } else {
      const lastGroup = groupedHeadings[groupedHeadings.length - 1];
      lastGroup.items.push(heading);
    }
  }

  return groupedHeadings;
};
