import { NextSeo } from "next-seo";
import * as React from "react";

interface PrismicSeoProps {
  seo?: {
    metaTitle: string | null;
    metaDescription: string | null;
    socialCards: {
      description: string | null;
      image: { alt: string | null; dimensions: { width: number; height: number }; url: string } | null;
      title: string | null;
    }[];
  };
  canonical: string;
}

export const PrismicSeo = ({ canonical, seo }: PrismicSeoProps) => {
  if (!seo) return null;

  return (
    <NextSeo
      title={seo.metaTitle ?? undefined}
      description={seo.metaDescription ?? undefined}
      canonical={canonical}
      openGraph={{
        title: seo.socialCards[0]?.title ?? undefined,
        description: seo.socialCards[0]?.description ?? undefined,
        images: seo.socialCards[0]?.image?.url
          ? [
              {
                url: seo.socialCards[0].image.url,
                height: seo.socialCards[0].image.dimensions.height,
                width: seo.socialCards[0].image.dimensions.width,
                alt: seo.socialCards[0].image.alt ?? undefined,
              },
            ]
          : undefined,
      }}
    />
  );
};
