import type { Content } from "@prismicio/client";
import * as prismicH from "@prismicio/helpers";
import { Simplify } from "@prismicio/types/dist/value/types";

type FAQItem = Simplify<Content.FaqSectionSliceDefaultItem>;

export function isFAQ(item: Content.FaqDocument | { link_type: string }): item is Content.FaqDocument {
  return "id" in item;
}

/**
 * Utility function to get all FAQs on a Prismic page
 */
export const getFAQs = (slices: { slice_type: string; items: FAQItem[] }[]) => {
  const faqSlices = (slices ?? []).filter((slice) => slice.slice_type === "faq_section");

  const faqs = faqSlices.flatMap((faqSlice) => {
    return faqSlice.items.map((item) => {
      if (isFAQ(item.faq)) {
        if (!item.faq.data?.questionName || !item.faq.data?.acceptedAnswerText) {
          return null;
        }

        return {
          questionName: prismicH.asText(item.faq.data.questionName),
          acceptedAnswerText: prismicH.asText(item.faq.data.acceptedAnswerText),
        };
      }

      if (!item.question) {
        return null;
      }

      return {
        questionName: item.question,
        acceptedAnswerText: prismicH.asText(item.answer),
      };
    });
  });

  return faqs.filter((faq) => faq !== null) as {
    questionName: string;
    acceptedAnswerText: string;
  }[];
};
