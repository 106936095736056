import { getLayout } from "@/components/Layout";
import { PrismicPage } from "@/components/PrismicPage";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import type { PrismicDocument } from "@prismicio/types";
import { GetStaticPropsContext } from "next";
import React from "react";
import { createClient, PAGE_LINKS } from "../../prismic-configuration";

const Home = (props: any) => {
  React.useEffect(() => {
    logEvent(AMPLITUDE_EVENTS.indexPageView);
  }, []);

  return <PrismicPage {...props} />;
};

export async function getStaticProps({ previewData, locale }: GetStaticPropsContext) {
  const client = createClient({ previewData });
  let prismicDocument: PrismicDocument<Record<string, any>, string, string> | null = null;

  try {
    prismicDocument = await client.getSingle("home-page", { fetchLinks: PAGE_LINKS, lang: locale });
  } catch {
    try {
      prismicDocument = await client.getSingle("home-page", { fetchLinks: PAGE_LINKS, lang: "en-gb" });
    } catch {
      prismicDocument = null;
    }
  }

  if (!prismicDocument) {
    return {
      notFound: true,
    };
  }

  return {
    props: prismicDocument,
  };
}

Home.getLayout = getLayout;

export default Home;
