import { htmlSerializer } from "@/lib/htmlSerializer";
import { BannerData } from "@/types/banner-data";
import type { BoxProps } from "@chakra-ui/react";
import { Box, CloseButton, Collapse } from "@chakra-ui/react";
import { PrismicRichText } from "@prismicio/react";
import { useRouter } from "next/router";
import { parseCookies, setCookie } from "nookies";
import { CtabannerDocument } from "prismicio-types";
import * as React from "react";
import { Container } from "../Container";

interface BannerProps {
  bannerData?: BannerData;
}

export const Banner = ({ bannerData, ...rest }: BannerProps & BoxProps) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const bannerUid = bannerData?.uid;
  const router = useRouter();

  const uid = router.query.uid as string;
  const citySlug = (bannerData?.data as CtabannerDocument["data"])?.citySlug;

  React.useEffect(() => {
    const cookies = parseCookies();
    if (!bannerUid) {
      return;
    }

    const shouldShowBanner = citySlug ? citySlug === uid && !cookies[bannerUid] : !cookies[bannerUid];

    if (shouldShowBanner) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [bannerUid, citySlug, uid]);

  if (!bannerUid) {
    return null;
  }

  const handleClick = () => {
    setCookie(null, bannerData.uid, "true", {
      // 1 day
      maxAge: 24 * 60 * 60,
    });
    setOpen(false);
  };

  return (
    <Collapse in={open} animateOpacity>
      <Box
        bg="vanilla"
        __css={{
          a: {
            color: "inherit",
            textDecoration: "underline",
          },
        }}
        {...rest}
      >
        <Container
          display="grid"
          px={{ base: 4, md: 10, xl: 20 }}
          py={4}
          gridTemplateColumns={{ base: "1fr max-content" }}
          gridGap={4}
        >
          <PrismicRichText field={bannerData.data.content} components={htmlSerializer} />
          <CloseButton onClick={handleClick} size="sm" />
        </Container>
      </Box>
    </Collapse>
  );
};
