import { PrismicSeo } from "@/components/PrismicSeo";
import { getFAQs } from "@/utils/getFAQs";
import { getTOCGroupedHeadings } from "@/utils/getTOCGroupedHeadings";
import { SliceZone } from "@prismicio/react";
import { FAQPageJsonLd } from "next-seo";
import { components } from "../../slices";
import { TOCContext } from "../TableOfContents/TableOfContents";

export const PrismicPage = (props: any) => {
  const groupedHeadings = getTOCGroupedHeadings(props.data?.slices);
  const faqs = getFAQs(props.data?.slices);

  return (
    <TOCContext.Provider value={{ groupedHeadings }}>
      {faqs.length > 0 && (
        <FAQPageJsonLd
          mainEntity={faqs.map(({ questionName, acceptedAnswerText }) => {
            return {
              questionName,
              acceptedAnswerText,
            };
          })}
        />
      )}
      <PrismicSeo canonical={`https://www.joinlifex.com${props.url}`} seo={props.data} />
      <SliceZone {...props.data} components={components} />
    </TOCContext.Provider>
  );
};
